<template>
<!-- 考试安排 -->
	<div class="main-examCon">
		<div class="ad-figure"><img src="@/assets/images/ad-figure.jpg"></div>
		<div class="noticeNper">
			<div class="" v-for="(itemP,indexP) in qishu" :key="indexP">
				<div class="journal flex flex-between">
					<span class="years">{{itemP.exam_date_time | formatDate}}</span>
					<span class="nper">{{itemP.exam_date_title}}</span>
				</div>
				<ul class="notice-ul">
					<li v-for="(item, index) in itemP.child" :key="index">
						<div class="top flex flex-between">
							<h3 class="title" @click="openBlank('NewsDetail',item.article_id)">
								<el-link :underline="false" :title="item.title">{{item.title}}</el-link>
							</h3>
							<span class="release-time">{{item.update_time | formatDate}}</span>
						</div>
						<div class="bottom flex flex-between">
							<p v-if="item.thumb" class="shortTxt">{{item.content}}</p>
							<p v-else class="longTxt">{{item.content}}</p>
							<div class="thumb" v-if="item.thumb">
								<el-image :src="item.thumb" fit="cover" class="portrait image-font">
									<div slot="error" class="image-slot">
										<img src="@/assets/images/default.png" alt="">
									</div>
								</el-image>
							</div>
						</div>
					</li>

				</ul>
			</div>
		</div>
		<div class="paging">
			<el-pagination 
			background 
			:current-page="page" 
			:hide-on-single-page="true" 
			@current-change="getNoticeList" 
			layout="prev, pager, next" 
			:page-count="totalPage">
			</el-pagination>
		</div>
		<el-empty description="暂无数据" v-if="!qishu.length"></el-empty>
	</div>
</template>

<script>
	import {formatDate} from '@/utils/timeStamp.js'
	export default {
		data() {
			return {
				page:1,
				qishu:[],
				totalPage:0,
			}
		},
		created() {
			this.getNoticeList(this.page);
		},
		filters: {
			formatDate(time) {
				time = time * 1000
				let date = new Date(time)
				return formatDate(date, 'yyyy-MM-dd')
			}
		},		
		methods: {
			// 列表数据
			getNoticeList(page) {				
				this.$http.get('/api/web/news/getExamSchedule?page='+page)
					.then((res) => {
						const {list}=res.data;	
						let dataInfo = {};
						list.forEach((item, index) => {
							let { exam_date_title } = item;
							if (!dataInfo[exam_date_title]) {
								dataInfo[exam_date_title] = {
									exam_date_title,			
									child: []
								}
							}
							dataInfo[exam_date_title].exam_date_time = item.exam_date_time
							dataInfo[exam_date_title].child.push(item);
						});
						let dataList = Object.values(dataInfo); // list 转换成功的数据
						this.qishu = dataList
						this.totalPage=res.data.totalPage; 
						
				})
			},
			openBlank(name,id){
				let routeUrl = this.$router.resolve({
          name,
					query:{id}
				});
				window.open(routeUrl.href, '_blank');
			},
			// 详情页跳转
			gotoLink(id){
				this.$router.push({
					name:'NewsDetail',
					query:{id},
				},)
			}
		}
	}
</script>

<style lang="less" scoped>
	.main-examCon {
		width: 100%;
		.ad-figure {
			width: 1070px;
			border-radius: 10px;
			margin-bottom: 20px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
			}
		}
		.noticeNper {
			width: 100%;
			background-color: white;
			border-radius: 10px;
			padding: 10px 15px 0;
			margin: 0 0 30px;
			overflow: hidden;
			.journal {
				width: 275px;
				margin: 30px auto 10px;
				align-items: center;
				border-radius: 10px;
				border: solid 1px var(--main-active-color);
				overflow: hidden;
				span {
					display: block;
					height: 48px;
					line-height: 48px;
					font-size: 20px;
				}
				.years {
					width: 160px;
					color: #ffffff;
					background-color: var(--main-active-color);
				}
				.nper {
					width: calc(100% - 160px);
					color: var(--main-active-color);
				}
			}
			.notice-ul {
				width: 100%;
				margin: 0;
				padding: 0 0 20px;
				li {
					position: relative;
					list-style: none;
					margin-top: 30px;
					padding: 25px 20px;
					-webkit-transition: all 0.5s;
					-moz-transition: all 0.5s;
					-ms-transition: all 0.5s;
					-o-transition: all 0.5s;
					transition: all 0.5s;			
					border-radius: 5px;
					&:last-child{
						&::after{
							border-bottom: none;
						}				
					}
					&::after{
						display: block;
						content: '';
						position: absolute;
						left: 15px;
						bottom: -20px;
						width: calc(100% - 30px);
						height: 1px;
						border-bottom: solid 1px #dadada;
					}
					&:hover{
						background: #f5f5f5;
					}			
					.top {
						.title {
							width: 900px;
							height: 32px;
							font-size: 24px;
							line-height: 32px;
							text-align: left;
							color: #333333;
							margin: 0;
							padding: 0;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							/deep/.el-link--inner {
								display: block;
								width: 900px;
								font-size: 24px;
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
							}
						}
						.release-time {
							height: 24px;
							font-size: 14px;
							color: #666666;
							line-height: 24px;
							text-align: right;
							margin-top: 8px;
						}
					}
					.bottom {
						margin-top: 25px;
						p.longTxt{
							width: 100%;
							font-size: 15px;
							color: #666666;
							line-height: 28px;
							text-align: left;
							margin-top: 0;
							margin-bottom: 0;
						}
						p.shortTxt{
							width: 695px;
							font-size: 15px;
							color: #666666;
							line-height: 28px;
							text-align: left;
							margin-top: 0;
							margin-bottom: 0;
							margin-right: 30px;
						}
						.thumb {
							.el-image{
								width: 280px;
								height: 170px;
								max-height: 170px;
								border-radius: 4px;
							}
						}
					}
				}
				li:last-child {
					border: none;
				}
			}
		}	
		.paging{
			width: 100%;
			margin-top: 20px;		
			/deep/.el-pager li{
				font-size: 16px;
				width: 40px;
				height: 40px;
				line-height: 40px;
				font-weight: normal;
				background-color: white;
			}
			/deep/.el-pager li:not(.disabled).active {
			    background-color: #d71a18;
			    color: #fff;
			}
			/deep/.btn-next,/deep/.btn-prev{
				background-color: white;
				font-size: 16px;
				width: 40px;
				height: 40px;
				line-height: 40px;
				font-weight: normal;
			}
		}
		.clearfix {
			clear: both;
		}
	}
</style>
